import { APIResponse, Product } from '../types/api';
import { logDebug } from '../utils/logger';

const API_URL = process.env.REACT_APP_CUSTOM_API_URL as string;

export const getProductList = async (): Promise<APIResponse<Product[]>> => {
  const response = await fetch(`${API_URL}/store/products`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to load products. Please try again later.');
  }

  return response.json();
};

export const getProductById = async(productId: string): Promise<APIResponse<Product>> => {
  const response = await fetch(`${API_URL}/store/products/${productId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to load products. Please try again later.');
  }

  const resp = await response.json();
  return {
    success: true,
    data: resp.product as Product,
  }
}

export const registerAndRedeemGiftCard = async (
  userData: any,
  giftCardId: string,
): Promise<APIResponse<any>> => {
  const response = await fetch(`${API_URL}/store/register-gc`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: userData, giftCardId }),
  });

  if (!response.ok) {
    throw new Error('Failed to redeem gift card');
  }

  const data = await response.json();
  return {
    success: true,
    data: data
  }
};

export const validateProductKey = async (giftCardId: string): Promise<APIResponse<any>> => {
  const response = await fetch(`${API_URL}/store/gc/${giftCardId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    logDebug("Failed to validate gift card");
    throw new Error('Failed to validate gift card');
  }

  const { message, value } = await response.json();
  return {
    success: true,
    data: value,
    message: message,
  };
};



/**
 * Use this to checkout a user for a specific product.
 * Registers the user with the product ID and proceeds to checkout.
 *
 * @param userData - The user object containing user information.
 * @param productId - The product ID for checkout.
 * @returns {Promise<any>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const registerAndCheckoutUser = async (userData: object, productId: string, qty: number = 1, selectedPrice?: string): Promise<any> => {
    const response = await fetch(`${API_URL}/auth/register/${productId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        user: userData, 
        qty: qty,
        price: selectedPrice ?? undefined 
      }),
    });
  
    if (!response.ok) {
      throw new Error('Failed to register and checkout user');
    }
  
    return await response.json();
  };
