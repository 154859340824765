import { Microsoft } from '@mui/icons-material';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getMSALToken, getPedegreeMsalToken, msalInstance } from '../msalConfig';
import { loginUser, loginUserSSO, tryAdminLogin } from '../services/api';
import { logDebug } from '../utils/logger';
import { showErrorToast, showSuccessToast } from '../utils/toastUtils';

interface FormData {
  username: string;
  password: string;
  recaptchaToken?: string;
}

const Login: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({ username: '', password: '' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePedegreeLogin = async (): Promise<void> => {
    try {
      setLoading(true);
      const user = await getPedegreeMsalToken()!;
      logDebug('Pedegree user retrieved:', user);

      try {
        const { data: { isAdmin } } = await tryAdminLogin(user.accessToken!);
        login(
          {
            name: user.name, 
            isAdmin: isAdmin
          }, 
          user.token,
          user.accessToken!,
        );
        showSuccessToast('Successfully logged in as admin.');
        navigate('/protected/admin');
      } catch (error: any) {
        showErrorToast(error.message || 'Failed to login with Pedegree');
        msalInstance.clearCache();
      }
    } catch (error) {
      logDebug('Error during Pedegree login:', error);
      showErrorToast('Failed to login with Pedegree');
    } finally {
      setLoading(false);
    }
  };

  const handleMSALLogin = async (): Promise<void> => {
    try {
      setLoading(true);
      const user = await getMSALToken();
      logDebug('MSAL user retrieved:', user);

      try {
        const { data } = await loginUserSSO(user);
        logDebug('loginUserSSO response: ', data);
        login(data, data.token);
        showSuccessToast('Successfully logged in with Microsoft.');
        navigate('/protected/profile');
      } catch (error: any) {
        showErrorToast(error.message || 'Failed to login with Microsoft');
        msalInstance.clearCache();
      }
    } catch (error) {
      logDebug('Error during MSAL login:', error);
      showErrorToast('Failed to login with Microsoft');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    try {
      const {data: response} = await loginUser(formData);
      login(response, response.token);
      showSuccessToast('Successfully logged in.');
      navigate('/protected/profile');
    } catch (error: any) {
      logDebug('Error during login:', error);
      showErrorToast('Invalid username or password');
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}
    >
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <TextField
        label="Username"
        name="username"
        value={formData.username}
        disabled={loading}
        onChange={handleChange}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        disabled={loading}
        onChange={handleChange}
        required
        sx={{ mb: 2 }}
      />
      <Button type="submit" disabled={loading} variant="contained" color="primary" sx={{ mt: 2 }}>
        Login
      </Button>
      <Button
        variant="contained"
        startIcon={!loading && <Microsoft />}
        color="primary"
        sx={{ mt: 2, ml: 2 }}
        disabled={loading}
        onClick={handleMSALLogin}
      >
        {loading && <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />}
        Sign in with Microsoft
      </Button>
      <Button
        variant="contained"
        startIcon={!loading && <Microsoft />}
        color="primary"
        sx={{ mt: 2, ml: 2 }}
        disabled={loading}
        onClick={handlePedegreeLogin}
      >
        {loading && <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />}
        Admin Login (SSO)
      </Button>
    </Box>
  );
};

export default Login;
