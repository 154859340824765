import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { logDebug } from '../utils/logger';

/**
 * This class manages the global authentication state of the user
 * 
 * All functions should be called only AFTER a component has been able
 * to successfully validate the user's credentials.
 */

// Define the types for user data and context
interface UserData {
  // id: string;
  name: string;
  email?: string;
  gamertag?: string;
  ageGroup?: string;
  customerId?: string;
  isAdmin?: boolean;
  [key: string]: any;

}

interface AuthContextProps {
    user: UserData | null;
    accessToken: string | null; // Used for accessing resources in azure/MSAL
    idToken: string | null;     // Used for JWT validation of a user.
    betaAccessValidated: boolean;
    login: (user: UserData, idToken: string, accessToken?: string) => void;
    logout: () => void;
    validateBetaAccess: (value?: boolean) => void;
  }
  
  const AuthContext = createContext<AuthContextProps | undefined>(undefined);
  
  export const AuthProvider: React.FC<{children: ReactNode}> = ({ children }) => {
    const [user, setUser] = useState<UserData | null>(() => {
      const userString = sessionStorage.getItem('user');
      return userString ? JSON.parse(userString) : null;
    });
    const [accessToken, setAccessToken] = useState<string | null>(() => {
      return sessionStorage.getItem('accessToken');
    });
    const [idToken, setIdToken] = useState<string | null>(() => {
      return sessionStorage.getItem('idToken');
    });
    const [betaAccessValidated, setBetaAccessValidated] = useState<boolean>(() =>
      sessionStorage.getItem('betaAccessValidated') === 'true'
    );
  
    const login = (user: UserData, idToken: string, accessToken?: string) => {
      setUser(user);
      setIdToken(idToken);
      if(accessToken) setAccessToken(accessToken);
    };

    useEffect(() => {
      if (user) {
        logDebug('User Data Saved to Session Storage: ', user);
        sessionStorage.setItem('user', JSON.stringify(user));
      } else {
        logDebug('User Data Removed from Session Storage');
        sessionStorage.removeItem('user');
      }
    }, [user]);

    useEffect(() => {
      if(accessToken) {
        logDebug('Token Saved to Session Storage: ', accessToken);
        sessionStorage.setItem('accessToken', accessToken);
      }else{
        logDebug('Token Removed from Session Storage');
        sessionStorage.removeItem('accessToken');
      }
    }, [accessToken]);

    useEffect(() => {
      if(idToken) {
        logDebug('ID Token Saved to Session Storage: ', idToken);
        sessionStorage.setItem('idToken', idToken);
      } else {
        logDebug('ID Token Removed from Session Storage');
        sessionStorage.removeItem('idToken');
      }
    }, [idToken]);
  
    const logout = () => {
      setUser(null);
      setAccessToken(null);
      setIdToken(null);
      setBetaAccessValidated(false);
      sessionStorage.removeItem('betaAccessValidated');
    };
  
    const validateBetaAccess = (value: boolean = true) => {
      setBetaAccessValidated(value);
      sessionStorage.setItem('betaAccessValidated', value.toString());
    };
  
    return (
      <AuthContext.Provider
        value={{
          user,
          accessToken: accessToken,
          idToken: idToken,
          betaAccessValidated,
          login,
          logout,
          validateBetaAccess,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };

  export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
  };
  