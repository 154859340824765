import AppBar from '@mui/material/AppBar';
import Button, { ButtonProps } from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import dark_falcon from '../assets/logo_300_black_full.png';
import full_falcon from '../assets/logo_300_white_full.png';
import { useAuth } from '../contexts/AuthContext';
import { msalInstance } from '../msalConfig';
import { logoutUser } from '../services/api';
import { logError } from '../utils/logger';
import { clearToken } from '../utils/session';
import RedeemDialog from './RedeemProduct/RedeemDialog';

import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

type StyledButtonProps = ButtonProps & {
  color?: 'primary' | 'secondary';
  component?: React.ElementType;
  to?: string;
};

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, color = 'primary' }: { theme: Theme; color?: 'primary' | 'secondary' }) => ({
    color: 'inherit',
    ':hover': {
      backgroundColor: theme.palette[color]?.light,
    },
  })
);

const Header: React.FC = () => {
  const { user } = useAuth();
  const [openRedeemDialog, setOpenRedeemDialog] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const isLoginRoute = useMatch('/login');
  const isRegisterRoute = useMatch('register/*');
  const isRedeemRoute = useMatch('redeem/*');

  const noButtonHeader = isLoginRoute || isRegisterRoute || isRedeemRoute;

  // Environment Variables
  const isTestMode = process.env.REACT_APP_TEST_MODE === 'true';
  const isDebugEnabled = process.env.REACT_APP_DEBUG === 'true';
  const isQAEnabled = process.env.REACT_APP_QA_MODE === 'true';

  const handleLogout = async () => {
    try {
      await logoutUser();
      clearToken();
      sessionStorage.clear();
      await msalInstance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin,
      });
    } catch (error) {
      logError('Error during logout:', error);
    }
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  // Define the navigation items once, based on conditions
  const supportButton = (
    <StyledButton color="primary" component={Link} to="https://support.pedegreestudios.com/customer/login">
      Support
    </StyledButton>
  );

  const profileButton = (
    <StyledButton color="primary" component={Link} to="/protected/profile">
      Profile
    </StyledButton>
  );

  const logoutButton = (
    <StyledButton color="primary" onClick={handleLogout}>
      Logout
    </StyledButton>
  );

  const storeButton = (
    <StyledButton color="primary" component={Link} to="/store">
      Store
    </StyledButton>
  );

  // const loginButton = (
  //   <StyledButton color="primary" component={Link} to="/home">
  //     Login
  //   </StyledButton>
  // );

  const redeemButton = (
    <StyledButton color="primary" onClick={() => setOpenRedeemDialog(true)}>
      Redeem Code
    </StyledButton>
  );

  const [navItems, setNavItems] = React.useState<React.ReactNode[]>([]);

  React.useEffect(() => {
    const newNavItems = user
      ? [supportButton, profileButton, storeButton, logoutButton]
      : noButtonHeader
        ? [storeButton]
        : [
            redeemButton,
            <RedeemDialog
              key="redeem-dialog"
              open={openRedeemDialog}
              product={undefined}
              onClose={() => setOpenRedeemDialog(false)}
            />,
            storeButton
          ];

    setNavItems(newNavItems);
    // Re-run whenever these dependencies change
  }, [user, noButtonHeader, openRedeemDialog]);

  // const navItems = user
  //   ? [supportButton, profileButton, logoutButton]
  //   : noButtonHeader
  //     ? [storeButton]
  //     : [
  //         redeemButton,
  //         <RedeemDialog
  //           key="redeem-dialog"
  //           open={openRedeemDialog}
  //           product={undefined}
  //           onClose={() => setOpenRedeemDialog(false)}
  //         />,
  //         loginButton
  //       ];

  const mobileNavList = (
    <Box
      sx={{ width: 250, p: 2 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={dark_falcon} alt="Pedegree Studios" style={{ height: '50px', borderRadius: 0 }} />
      </Box>
      <Box mb={2}>
        {isTestMode && <Chip label="Development Release" color="info" sx={{ mr: 1, mb:1 }} />}
        {isDebugEnabled && <Chip label="Debug Enabled" color="warning" sx={{ mb:1 }} />}
        {isQAEnabled && <Chip label="QA Release" color="success" />}
      </Box>
      {navItems.map((item, index) => (
        <Box key={index} sx={{ mb: 1 }}>
          {item}
        </Box>
      ))}
    </Box>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Logo always visible */}
        <StyledButton
          color="primary"
          component={Link}
          to="/"
          sx={{ p: 0, mr: 2 }}
        >
          <img src={full_falcon} alt="Pedegree Studios" style={{ height: '50px', borderRadius: 0 }} />
        </StyledButton>

        {/* Chips visible on larger screens */}
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: 'center',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          {isTestMode && <Chip label="Development Release" color="info" sx={{ mr: 1 }} />}
          {isDebugEnabled && <Chip label="Debug Enabled" color="warning" sx={{ mr: 1 }} />}
          {isQAEnabled && <Chip label="QA Release" color="success" />}
        </Typography>

        {/* Desktop navItems visible on larger screens */}
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1, alignItems: 'center' }}>
          {navItems}
        </Box>

        {/* Mobile Menu Button visible on smaller screens */}
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{ ml: 'auto', display: { xs: 'block', sm: 'none' } }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          sx={{backgroundColor: 'primary.main'}}
        >
          {mobileNavList}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
