/* eslint-disable no-console */
const isConsoleLoggingEnabled = process.env.REACT_APP_DEBUG === 'true';

export const logDebug = (...args: any[]): void => {
  if (isConsoleLoggingEnabled) {
    console.log('[DEBUG]', ...args);
  }
};

export const logInfo = (...args: any[]): void => {
  if (isConsoleLoggingEnabled) console.info('[INFO]', ...args);
};

export const logWarn = (...args: any[]): void => {
    if(isConsoleLoggingEnabled) {
        console.warn('[WARN]', ...args);
    }
};  

export const logError = (...args: any[]): void => {
  if(isConsoleLoggingEnabled) console.error('[ERROR]', ...args);
};