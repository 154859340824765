const API_URL = process.env.REACT_APP_CUSTOM_API_URL as string;

export const getContainerData = async (token: string): Promise<any> => {
  const response = await fetch(`${API_URL}/admin/containers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to load container data. Please try again later.');
  }

  return await response.json();
};

/**
 * Start a container group.
 * @param token - Admin authorization token.
 * @param resourceGroup - The resource group of the container.
 * @param containerGroup - The container group to start.
 * @returns {Promise<string>} - The response text indicating success.
 * @throws {Error} - If the request fails.
 */
export const startContainerGroup = async (
  token: string,
  resourceGroup: string,
  containerGroup: string
): Promise<string> => {
  const response = await fetch(`${API_URL}/admin/containers/${resourceGroup}/${containerGroup}/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to start container group');
  }

  return await response.text();
};

/**
 * Stop a container group.
 * @param token - Admin authorization token.
 * @param resourceGroup - The resource group of the container.
 * @param containerGroup - The container group to stop.
 * @returns {Promise<string>} - The response text indicating success.
 * @throws {Error} - If the request fails.
 */
export const stopContainerGroup = async (
  token: string,
  resourceGroup: string,
  containerGroup: string
): Promise<string> => {
  const response = await fetch(`${API_URL}/admin/containers/${resourceGroup}/${containerGroup}/stop`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to stop container group');
  }

  return await response.text();
};

/**
 * Restart a container group.
 * @param token - Admin authorization token.
 * @param resourceGroup - The resource group of the container.
 * @param containerGroup - The container group to restart.
 * @returns {Promise<string>} - The response text indicating success.
 * @throws {Error} - If the request fails.
 */
export const restartContainerGroup = async (
  token: string,
  resourceGroup: string,
  containerGroup: string
): Promise<string> => {
  const response = await fetch(`${API_URL}/admin/containers/${resourceGroup}/${containerGroup}/restart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to restart container group');
  }

  return await response.text();
};
