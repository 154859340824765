// src/utils/toastUtils.ts
import { toast, ToastOptions } from 'react-toastify';

// Default Toast Configuration
const defaultOptions: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

// Success Toast
export const showSuccessToast = (message: string, options: ToastOptions = {}): void => {
  toast.success(message, { ...defaultOptions, ...options });
};

// Error Toast
export const showErrorToast = (message: string, options: ToastOptions = {}): void => {
  toast.error(message, { ...defaultOptions, ...options });
};

// Info Toast
export const showInfoToast = (message: string, options: ToastOptions = {}): void => {
  toast.info(message, { ...defaultOptions, ...options });
};

// Warning Toast
export const showWarningToast = (message: string, options: ToastOptions = {}): void => {
  toast.warn(message, { ...defaultOptions, ...options });
};

export const showCustomToast = (content: React.ReactNode, options: ToastOptions = {}): void => {
    toast(content, { ...defaultOptions, ...options });
  };