import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';

interface FeedbackCardProps {
  email: string;
  name: string;
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({ email, name }) => (
  <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
    <CardHeader title="Feedback" />
    <CardContent>
      <Typography variant="body1" gutterBottom>
        Please provide feedback on your experience with the Overqualified! 2025 Learning Pass.
      </Typography>
      <iframe
        title="Feedback Form"
        className="freshwidget-embedded-form"
        id="freshwidget-embedded-form"
        src={`https://pedegreestudios.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Feedback&helpdesk_ticket[requester]=${email}&disable[requester]=true&helpdesk_ticket[email]=${email}&disable[email]=true&helpdesk_ticket[name]=${name}&helpdesk_ticket[subject]=Overqualified! Learning Pass Feedback`}
        scrolling="no"
        height="500px"
        width="100%"
        frameBorder="0"
      />
    </CardContent>
  </Card>
);

export default FeedbackCard;
