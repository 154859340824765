import { Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { logDebug } from '../../utils/logger'; // Logging utility
import { showErrorToast } from '../../utils/toastUtils'; // Toast utility

// Props Type
interface ProductKeyInputCardProps {
  onSubmit: (giftCardCode: string) => void;
  product: any;
}

const ProductKeyInputCard: React.FC<ProductKeyInputCardProps> = ({ product, onSubmit }) => {
  const [giftCardCode, setGiftCardCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // Helper function to calculate the Mod10 checksum
  const isValidChecksum = (segment: string): boolean => {
    let sum = 0;
    for (let i = 0; i < segment.length - 1; i++) {
      sum += segment.charCodeAt(i);
    }
    const missingChar = (10 - (sum % 10)) % 10;
    return segment.charCodeAt(segment.length - 1) === missingChar + 48; // 48 for '0' in ASCII
  };

  // Validates each segment as the user types
  const validateSegments = (formattedKey: string): boolean => {
    const segments = formattedKey.split('-');
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (segment.length === 5 && !isValidChecksum(segment)) {
        const errorMsg = `Segment ${i + 1} is invalid.`;
        setError(errorMsg);
        logDebug(errorMsg);
        return false;
      }
    }
    setError(''); // Clear error if all segments are valid so far
    return true;
  };

  // Handle change for the access key input
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let input = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''); // Only allows uppercase letters and numbers

    // Limit input to 25 characters (5 segments of 5 characters each)
    if (input.length > 25) {
      input = input.substring(0, 25);
    }

    let formattedKey = '';

    // Insert hyphens after every 5 characters
    for (let i = 0; i < input.length; i += 5) {
      if (i + 5 < input.length) {
        formattedKey += input.substring(i, i + 5) + '-';
      } else {
        formattedKey += input.substring(i);
      }
    }

    // Check validity of segments as user types
    if (validateSegments(formattedKey)) {
      setGiftCardCode(formattedKey);
    } else {
      setGiftCardCode(formattedKey.slice(0, -1)); // Keep the access key without the latest invalid character
    }
  };
  // Generate a placeholder string with dashes and gray slots
  const generatePlaceholder = (input: string): string => {
    let placeholder = '';
    for (let i = 0; i < 25; i++) {
      if (i > 0 && i % 5 === 0) {
        placeholder += '-';
      }
      if (i < input.length) {
        placeholder += input[i];
      } else {
        placeholder += 'X'; // Placeholder character
      }
    }
    return placeholder;
  };

  const placeholder = generatePlaceholder(giftCardCode);

  // Handles the submission of the access key
  const handleAccessKeySubmit = (e: React.FormEvent<HTMLFormElement | HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>): void => {
    e.preventDefault();

    if (error || !validateSegments(giftCardCode)) {
      const errorMsg = 'Please correct the key format or checksum errors before submitting.';
      setError(errorMsg);
      showErrorToast(errorMsg);
      return;
    }

    setLoading(true);
    logDebug('Submitting gift card code:', giftCardCode);
    try {
      onSubmit(giftCardCode); // Call the onSubmit callback with the validated access key
      // showSuccessToast('Gift card code redeemed successfully!');
    } catch (err) {
      logDebug('Error during submission:', err);
      // showErrorToast('Failed to redeem the gift card code.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" align="center">
          ⌨ Redeem {(product && product?.name) ? product.name : `Code`} 🔢
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Please enter your product key code to redeem your license to {(product && product?.name) ? product.name : `your product`}.
        </Typography>

        <TextField
          autoFocus
          margin="dense"
          label="Product Key"
          error={!!error}
          helperText={error}
          type="text"
          fullWidth
          value={giftCardCode}
          placeholder={placeholder}
          InputProps={{
            sx: {
              textAlign: 'center',
              '& input': {
                textAlign: 'center',
              },
            },
          }}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleAccessKeySubmit(e);
          }}
        />
      </CardContent>

      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleAccessKeySubmit}
          >
            Submit
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default ProductKeyInputCard;

// import { Box, Button, Card, CardActions, CardContent, CircularProgress, Typography } from '@mui/material';
// import React, { useState } from 'react';
// import { showErrorToast, showSuccessToast } from '../../utils/toastUtils'; // Toast utility

// interface ProductKeyInputCardProps {
//   onSubmit: (giftCardCode: string) => void;
//   product: any;
// }

// const ProductKeyInputCard: React.FC<ProductKeyInputCardProps> = ({ product, onSubmit }) => {
//   const [giftCardCode, setGiftCardCode] = useState<string>('');
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string>('');

//   const isValidChecksum = (segment: string): boolean => {
//     let sum = 0;
//     for (let i = 0; i < segment.length - 1; i++) {
//       sum += segment.charCodeAt(i);
//     }
//     const missingChar = (10 - (sum % 10)) % 10;
//     return segment.charCodeAt(segment.length - 1) === missingChar + 48; // 48 for '0' in ASCII
//   };

//   const validateSegments = (formattedKey: string): boolean => {
//     const segments = formattedKey.split('-');
//     for (let i = 0; i < segments.length; i++) {
//       const segment = segments[i];
//       if (segment.length === 5 && !isValidChecksum(segment)) {
//         setError(`Segment ${i + 1} is invalid.`);
//         return false;
//       }
//     }
//     setError('');
//     return true;
//   };

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
//     let input = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
//     if (input.length > 25) input = input.substring(0, 25);

//     let formattedKey = '';
//     for (let i = 0; i < input.length; i += 5) {
//       if (i + 5 < input.length) formattedKey += input.substring(i, i + 5) + '-';
//       else formattedKey += input.substring(i);
//     }

//     if (validateSegments(formattedKey)) setGiftCardCode(formattedKey);
//   };

//   const generatePlaceholder = (input: string): string => {
//     let placeholder = '';
//     for (let i = 0; i < 25; i++) {
//       if (i > 0 && i % 5 === 0) placeholder += '-';
//       placeholder += i < input.length ? input[i] : '•'; // '•' is the placeholder
//     }
//     return placeholder;
//   };

//   const handleAccessKeySubmit = (e: React.FormEvent<HTMLFormElement | HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>): void => {
//     e.preventDefault();
//     if (error || !validateSegments(giftCardCode)) {
//       showErrorToast('Please correct the key format or checksum errors before submitting.');
//       return;
//     }

//     setLoading(true);
//     try {
//       onSubmit(giftCardCode);
//       showSuccessToast('Gift card code redeemed successfully!');
//     } catch (err) {
//       showErrorToast('Failed to redeem the gift card code.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const placeholder = generatePlaceholder(giftCardCode);

//   return (
//     <Card>
//       <CardContent>
//         <Typography variant="h5" align="center">
//           ⌨ Redeem {product.name} 🔢
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Please enter your product key code to redeem your license to {product.name}.
//         </Typography>

//         <Box
//           component="div"
//           sx={{
//             position: 'relative',
//             width: '100%',
//             height: '56px',
//             border: '1px solid',
//             borderColor: error ? 'error.main' : 'grey.400',
//             borderRadius: 1,
//             mt: 2,
//             px: 2,
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             fontFamily: 'monospace',
//             fontSize: '16px',
//             letterSpacing: '1px',
//           }}
//         >
//           <span
//             style={{
//               position: 'absolute',
//               color: 'grey',
//               left: '16px',
//               top: '50%',
//               transform: 'translateY(-50%)',
//               pointerEvents: 'none',
//               whiteSpace: 'nowrap',
//               fontFamily: 'monospace',
//               fontSize: '16px',
//               letterSpacing: '1px',
//               textAlign: 'center',
//               width: '100%',
//             }}
//           >
//             {placeholder}
//           </span>
//           <input
//             type="text"
//             value={giftCardCode}
//             onChange={handleChange}
//             maxLength={29}
//             style={{
//               position: 'absolute',
//               background: 'transparent',
//               border: 'none',
//               color: 'black',
//               outline: 'none',
//               width: '100%',
//               height: '100%',
//               fontFamily: 'monospace',
//               fontSize: '16px',
//               letterSpacing: '1px',
//               textAlign: 'center',
//             }}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') handleAccessKeySubmit(e);
//             }}
//           />
//         </Box>
//         {error && (
//           <Typography variant="body2" color="error" align="center" sx={{ mt: 1 }}>
//             {error}
//           </Typography>
//         )}
//       </CardContent>

//       <CardActions
//         sx={{
//           display: 'flex',
//           justifyContent: 'flex-end',
//         }}
//       >
//         {loading ? (
//           <CircularProgress size={24} />
//         ) : (
//           <Button variant="outlined" size="small" color="primary" onClick={handleAccessKeySubmit}>
//             Submit
//           </Button>
//         )}
//       </CardActions>
//     </Card>
//   );
// };

// export default ProductKeyInputCard;

