import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminPortal from '../components/AdminPortal/AdminPortal';
import Profile from '../components/Profile/Profile';
import { useAuth } from '../contexts/AuthContext';

const ProtectedAppRoutes: React.FC = () => {
  const { user } = useAuth();

  // if (!user) {
  //   // Redirect unauthenticated users to the login page
  //   return <Navigate to="/login" replace />;
  // }

  return (
    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route
        path="/admin"
        element={
          user?.isAdmin ? (
            <AdminPortal />
          ) : (
            <Navigate to="/protected/profile" replace />
          )
        }
      />
      {/* Catch-all route for authenticated users */}
      <Route path="*" element={<Navigate to="/protected/profile" replace />} />
    </Routes>
  );
};

export default ProtectedAppRoutes;