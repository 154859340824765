import { AccessKeyValidation, APIResponse, User } from '../types/api';

const API_URL = process.env.REACT_APP_CUSTOM_API_URL as string;

export const checkAndRegisterUser = async (user: User): Promise<APIResponse<User>> => {
  const response = await fetch(`${API_URL}/auth/check-register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    throw new Error('Failed to check and register user');
  }

  return response.json();
};

export const loginUser = async (data: { username: string; password: string }): Promise<APIResponse<User>> => {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to login: ${errorData.error}`);
  }

  return response.json();
};

export const registerUserSSO = async (user: any): Promise<APIResponse<User>> => {
  const response = await fetch(`${API_URL}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to register with SSO: ${errorData.error}`);
  }

  const { userData } = await response.json();
  return {
    success: true,
    data: userData,
  };
}

export const loginUserSSO = async (data: any): Promise<APIResponse<User>> => {
  const response = await fetch(`${API_URL}/auth/login-sso`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorMsg = await response.json();
    throw new Error(`Failed to login with SSO: ${errorMsg.error}`);
  }
  
  const { user } = await response.json();
  return {
    success: true,
    data: user,
  };

  };

export const logoutUser = async (): Promise<void> => {
  await fetch(`${API_URL}/auth/logout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const validateAccessKey = async (accessKey: string): Promise<APIResponse<AccessKeyValidation>> => {
  const response = await fetch(`${API_URL}/auth/validate-access-key`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ accessKey }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  return response.json();
};

export const validateAccessSession = async (): Promise<APIResponse<{ accessKey: string }>> => {
  const response = await fetch(`${API_URL}/auth/get-access-key`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  return response.json();
};
