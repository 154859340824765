import { Microsoft } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, TextField, Typography } from "@mui/material";

interface RegisterNewUserProps {
    loading: boolean;
    handleMSALLogin: () => void;
}

const RegisterNewUser: React.FC<RegisterNewUserProps> = ({ loading, handleMSALLogin }: RegisterNewUserProps) => (
    <Card>
      <CardHeader title="New Users: Create an Account" />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Passwordless sign-on coming soon. Please register using your Microsoft account (@outlook, @hotmail, @live, etc.) to continue.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <TextField
            label="Email Address"
            name="username"
            disabled={true}
            sx={{ mb: 2, width: '80%', '& .MuiInputBase-root': { backgroundColor: 'lightgray' } }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '80%' }}
            disabled={true}
          >
            Next
          </Button>
        </Box>
  
        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <Box sx={{ flex: 1, height: '1px', backgroundColor: 'lightgray' }} />
          <Typography variant="body2" color="text.secondary" sx={{ mx: 2 }}>
            OR
          </Typography>
          <Box sx={{ flex: 1, height: '1px', backgroundColor: 'lightgray' }} />
        </Box>
  
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            startIcon={!loading && <Microsoft />}
            color="primary"
            sx={{ mt: 2, width: '80%' }}
            disabled={loading}
            onClick={handleMSALLogin}
          >
            {loading && <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />}
            Register with Microsoft
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

export default RegisterNewUser;