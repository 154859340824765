import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { Avatar, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import React from 'react';

interface ProfileCardProps {
  name: string;
  email: string;
  gamertag: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ name, email, gamertag }) => (
  <Card sx={{ display: 'flex', flexDirection: 'column'}}>
    <CardHeader title="Profile" />
    <CardContent>
      <Grid container spacing={4}>
        <Grid item xs={2} sm={3} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <Avatar sx={{ bgcolor: deepPurple[500] }}>{name[0]}{name.split(' ')[1][0]}</Avatar>
        </Grid>
        <Grid item xs={10} sm={9} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          <Typography variant="h6" gutterBottom>
            {name}
          </Typography>
        </Grid>
        <Grid container spacing={0.5} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={12} sx={{ justifyContent: 'right', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {email}
            </Typography>
            <EmailOutlinedIcon sx={{ color: 'action', ml: 2 }} />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ justifyContent: 'right', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {gamertag}
            </Typography>
            <VideogameAssetIcon sx={{ color: 'action', ml: 2 }} />
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ProfileCard;
