import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Product } from '../../types/api';
import { logDebug } from '../../utils/logger';

export interface StripePrice {
  id: string;
  object: 'price';
  active: boolean;
  billing_scheme: 'per_unit' | 'tiered';
  created: number; // Unix timestamp
  currency: string; // ISO currency code in lowercase
  currency_options?: {
    [currency: string]: {
      custom_unit_amount?: {
        enabled: boolean;
        maximum?: number;
        minimum?: number;
        preset?: number;
      };
      tax_behavior?: 'exclusive' | 'inclusive' | 'unspecified';
      tiers?: Array<{
        flat_amount?: number;
        flat_amount_decimal?: string;
        unit_amount?: number;
        unit_amount_decimal?: string;
        up_to: number | 'inf';
      }>;
      unit_amount?: number;
      unit_amount_decimal?: string;
    };
  };
  custom_unit_amount?: {
    enabled: boolean;
    maximum?: number;
    minimum?: number;
    preset?: number;
  };
  livemode: boolean;
  lookup_key?: string;
  metadata: { [key: string]: string };
  nickname?: string;
  product: string | StripeProduct; // Expandable field
  recurring?: {
    aggregate_usage?: 'sum' | 'last_during_period' | 'last_ever' | 'max';
    interval: 'day' | 'week' | 'month' | 'year';
    interval_count: number;
    trial_period_days?: number;
    usage_type: 'metered' | 'licensed';
  };
  tax_behavior?: 'exclusive' | 'inclusive' | 'unspecified';
  tiers?: Array<{
    flat_amount?: number;
    flat_amount_decimal?: string;
    unit_amount?: number;
    unit_amount_decimal?: string;
    up_to: number | 'inf';
  }>;
  tiers_mode?: 'graduated' | 'volume';
  transform_quantity?: {
    divide_by: number;
    round: 'up' | 'down';
  };
  type: 'one_time' | 'recurring';
  unit_amount?: number;
  unit_amount_decimal?: string;
}

export interface StripeProduct {

  "id": string,
  "object": string,
  "active": boolean,
  "created": 1678833149,
  "default_price": StripePrice,
  "description": string | null,
  "images": string[],
  "marketing_features": any[],
  "livemode": boolean,
  "metadata": any,
  "name": string,
  "package_dimensions": any,
  "shippable": boolean | null,
  "statement_descriptor": string | null,
  "tax_code": string | null,
  "unit_label": string | null,
  "updated": number,
  "url": string | null
  active_price: StripePrice | undefined;
  additional_prices: StripePrice[] | undefined;
  qty: number;
  selectedProductId?: string;

}

interface ProductCardProps {
  product: Product;
  selectedTabId?: number;
  useBeta?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, selectedTabId, useBeta }) => {

  const navigate = useNavigate();

  const handleBuyNow = () => {
    // Redirect to purchase flow
    //Use the gift card id to redeem the gift card instead of the product id
    logDebug(`Old product id: ${product.id}`);
    product.selectedProductId = product.metadata.gift_card;
    logDebug('Navigating to purchase page for new product Id:', product.selectedProductId);

    if(useBeta){
      navigate('/beta/register/purchase', { state: { product: product } });
    }
    else{
      navigate('/register/purchase', { state: { product: product } });
    }
  };

  // const handleBuySelf = () => {
  //   // Redirect to purchase flow

  //   product.selectedProductId = product.id;
  //   logDebug('Navigating to purchase page for product:', product.selectedProductId);
  //   navigate('/beta/register/purchase', { state: { product: product } });
  // }

  const [productQty, setProductQty] = React.useState<number>(product.qty ?? 1);

  useEffect(() => {
    const updateQty = () => {
      if (selectedTabId === 0) {
        return 1;
      }
      if (selectedTabId === 1) {
        return 2;
      }
      if (selectedTabId === 2) {
        return 5;
      }
      return 1;
    }

    setProductQty(updateQty());
  }, [selectedTabId, setProductQty]);

  // const setProductQty = (qty: number) => {
  //   product.qty = qty;
  // }
  useEffect(() => {
    product.qty = productQty;
  }, [productQty, product]);

  // const handleRedeemGiftcard = () => {
  //   // Open redeem dialog or redirect
  //   window.location.href = `/redeem/giftcard?productId=${product.id}`;
  // };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        alt={product.name}
        width="100%"
        image={product.images[0]}
      />
      <CardContent>
        <Typography gutterBottom variant="h5">
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {product.description}
        </Typography>

      </CardContent>
      <CardActions sx={{ mt: 'auto' }}>
        {/* {product.active_price && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
              <Typography variant="h6" color="text.secondary">
              ${product.active_price.unit_amount! / 100}
              </Typography>
              <Chip label={`Discount`} color="secondary" size="small" sx={{ mt: 1 }} />
            </Box>
        )} */}

        {product.active_price ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProductPriceDisplay product={product} productQty={productQty} selectedTabId={selectedTabId} />
              </Grid>
              <Grid item xs={12}>
                <QuantitySelector productQty={productQty} setProductQty={setProductQty} selectedTabId={selectedTabId} />
              </Grid>

              {
                selectedTabId === 0 ? (
                  <>
                    <Grid item xs={12}>
                      <Button fullWidth variant="contained" color="secondary" onClick={handleBuyNow}>
                        Buy Now
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Button fullWidth variant="contained" color="secondary" onClick={handleBuyNow}>
                        Buy As Gift
                      </Button>
                    </Grid> */}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="secondary" onClick={handleBuyNow}>
                      Buy Now
                    </Button>
                  </Grid>
                )
              }

            </Grid>
          </>
        ) : (
          <Button fullWidth disabled variant="outlined" color="secondary">
            Coming Soon
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const QuantitySelector: React.FC<{ productQty: number, setProductQty: (qty: number) => void, selectedTabId?: number }> = ({ productQty, setProductQty, selectedTabId }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
        Passes:
      </Typography>
      {(selectedTabId === 2 || selectedTabId === 0 || productQty < 3) ? (
        <Button variant="outlined" disabled color="primary">
          -
        </Button>
      ) : (
        <Button variant="outlined" color="primary" onClick={() => setProductQty(Math.max(productQty - 1, 1))}>
          -
        </Button>
      )}
      <Typography variant="body1" sx={{ mx: 2 }}>
        {productQty}
      </Typography>
      {(selectedTabId === 0 || productQty >= 4) ? (
        <Button variant="outlined" disabled color="primary">
          +
        </Button>
      ) : (
        <Button variant="outlined" color="primary" onClick={() => setProductQty(Math.min(productQty + 1, 4))}>
          +
        </Button>
      )}
    </Box>
  );
};

const ProductPriceDisplay: React.FC<{ product: Product, productQty: number, selectedTabId?: number }> = ({ product, productQty }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

      {product.default_price.unit_amount !== product.active_price!.unit_amount ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '70%' }}>
          <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
            ${((productQty * product.default_price.unit_amount!) / 100).toFixed(2)}
          </Typography>
          <Typography variant="h6" color="text.primary" sx={{ ml: 0.5 }}>
            ${(product.active_price!.unit_amount! / 100 * productQty).toFixed(2)}
          </Typography>
          <Chip label="Discount" color="secondary" size="small" sx={{ ml: 0.25, mt: 0.5 }} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '70%' }}>
          <Typography variant="h6" color="text.primary">
            ${product.active_price!.unit_amount! / 100}
          </Typography>
        </Box>
      )}

    </Box>
  );
};


// const ProductPriceDisplay: React.FC<{ product: StripeProduct, productQty: number, selectedTabId?: number }> = ({ product, productQty, selectedTabId }) => {
//   return (
//     <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
//       {product.default_price.unit_amount != product.active_price!.unit_amount ? (
//         <>
//           <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
//             ${((productQty * product.default_price.unit_amount!) / 100).toFixed(2)}
//           </Typography>
//           <Typography variant="h6" color="text.primary" sx={{ ml: 0.5 }}>
//             ${(product.active_price!.unit_amount! / 100 * productQty).toFixed(2)}
//           </Typography>
//           <Chip label={`Discount`} color="secondary" size="small" sx={{ ml: 0.25, mt: 0.5 }} />
//         </>
//       ) : (
//         <>
//           <Typography variant="h6" color="text.primary">
//             ${product.active_price!.unit_amount! / 100}
//           </Typography>
//         </>
//       )

//       }


//     </Box>

//   )
// };

export default ProductCard;
