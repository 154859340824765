import { APIResponse, AdminPortalData } from '../types/api';

const API_URL = process.env.REACT_APP_CUSTOM_API_URL as string;

export const tryAdminLogin = async (token: string): Promise<APIResponse<{ isAdmin: boolean }>> => {
  const response = await fetch(`${API_URL}/admin/admin-login`, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to login: ${errorData.error}`);
  }

  const resp = await response.json();
  return {
    success: true,
    data: { isAdmin: true },
    message: resp.message,
  }
};

export const getAdminPortalData = async (
  token: string,
  pageSize: number,
  continuationToken?: string,
): Promise<APIResponse<AdminPortalData>> => {
  const response = await fetch(`${API_URL}/admin/admin-portal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ pageSize, continuationToken }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to get admin data: ${errorData.error}`);
  }

  const data = await response.json();
  return {
    success: true,
    data: data,
  }
};

export const deleteUser = async (
  token: string,
  userId: string,
): Promise<APIResponse<{ message: string }>> => {
  const response = await fetch(`${API_URL}/admin/delete-user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ userId }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to delete user: ${errorData.error}`);
  }

  const data = await response.json();
  return {
    success: true,
    data: data,
  }
};
