import React from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import MainLayout from './layouts/MainLayout';
import { msalInstance } from './msalConfig';
import AppRoutes from './routes/AppRoutes';
import ProtectedAppRoutes from './routes/ProtectedRoutes';

const App: React.FC = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <MainLayout>
          <Routes>
            {/* Public Routes */}
            <Route path = "/*" element={<AppRoutes />} />
            {/* Protected Routes */}
            <Route path = "/protected/*" element={<ProtectedAppRoutes />} />
          </Routes>
        </MainLayout>
      </AuthProvider>
    </MsalProvider>
  );
};

export default App;
