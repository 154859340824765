// UserTable.tsx
import { DeleteForeverTwoTone } from '@mui/icons-material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Chip, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteUser } from '../../api/admin';
import { useAuth } from '../../contexts/AuthContext';
import { logDebug } from '../../utils/logger';
import ConfirmDialog from './ConfirmDialog';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

type User = {
  id: string;
  username: string;
  gamerTag: string;
  instanceId: number;
  progress: string;
  active: boolean;
};

type UserTableProps = {
  users: User[];
};

const UserTable: React.FC<UserTableProps> = ({ users }) => {

  const { idToken } = useAuth();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const navigate = useNavigate();

  const handleDelete = (user: User) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setSelectedUser(null);
    setOpenDialog(false);
  };

  const handleDialogConfirm = async () => {
    await deleteUser(idToken!, selectedUser!.id);
    logDebug('Deleting user', selectedUser);
    handleDialogClose();
    navigate(0);
  };

  const columns = [
    { 
      field: 'username', 
      headerName: 'Name', 
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Box>
            
            <IconButton>
              <DeleteForeverTwoTone
                onClick={() => handleDelete(params.row)}
              />
            </IconButton>
            {params.value}
          </Box>
        );
      }
    },
    { field: 'gamerTag', headerName: 'Gamertag', flex: 1 },
    {
      field: 'active',
      headerName: 'Active',
      flex: 1,
      renderCell: (params: any) => (
        <Chip label={params.value ? 'Active' : 'Inactive'} color={params.value ? 'success' : 'error'} />
      ),
    },
    {
      field: 'instanceId',
      headerName: 'Instance ID',
      flex: 1,
      renderCell: (params: any) => (
        <Box>
          {params.value}
          <IconButton>
            <RestartAltIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <ConfirmDialog 
        open={openDialog} 
        onClose={handleDialogClose} 
        onConfirm={handleDialogConfirm} 
        message="Are you sure you want to delete all data for this User?"
      />
      <DataGrid rows={users} columns={columns} autoHeight />;
    </>
  
  );
};

export default UserTable;
