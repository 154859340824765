import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
            <Card sx={{mr: 4, ml: 4, justifyContent: 'center', justifyItems: 'center'}}>
                <CardContent>
                    <Box sx={{ display: 'flex-column', justifyItems: 'center', mb: 2 }}>
                    <Typography variant="h3" component="div" sx={{mb:3}}>
                        Uh-Oh! 404
                    </Typography>
                    <Typography variant="body1" sx={{mb:2}}>
                        Page Not Found
                    </Typography>
                    <Button variant="contained" color="primary" component={Link} to="/">
                        Go back to Home
                    </Button>
                    </Box>
                </CardContent>
            </Card>
    );
};

export default NotFound;