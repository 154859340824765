import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import HttpIcon from '@mui/icons-material/Http';
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface Subscription {
  name: string;
  isActive: boolean;
  url: string;
  port: string;
}

interface SubscriptionsProps {
  subscriptions: Subscription[];
  loading: boolean;
}

const Subscriptions: React.FC<SubscriptionsProps> = ({ subscriptions, loading }) => (
  <Grid item>
    {loading ? (
      <CircularProgress size={36} />
    ) : subscriptions.length > 0 ? (
      subscriptions.map((subscription, index) => (
        <Card key={index} sx={{ display: 'flex', flexDirection: 'column', p: 2, mb: 2 }}>
          <CardHeader
            title={subscription.name || 'DLC'}
            action={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: subscription.isActive ? 'green' : 'red',
                  width: 100,
                  height: 40,
                  borderRadius: 1,
                }}
              >
                <Typography sx={{ color: 'white', fontWeight: 'bold' }}>
                  {subscription.isActive ? 'Active' : 'Inactive'}
                </Typography>
              </Box>
            }
          />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Access your subscription by connecting to the Minecraft server below. Need help? Find step-by-step instructions with screenshots on our{' '}
                  <Link target="_blank" to="https://support.pedegreestudios.com/support/solutions/articles/154000183390">
                    Knowledge Base ↗
                  </Link>.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <HttpIcon sx={{ color: 'action', mr: 2 }} />
                <Typography variant="body2">{subscription.url || 'NO URL FOUND'}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <ElectricalServicesIcon sx={{ color: 'action', mr: 2 }} />
                <Typography variant="body2">{subscription.port || 'NO PORT FOUND'}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))
    ) : (
      <Typography>No subscriptions available.</Typography>
    )}
  </Grid>
);

export default Subscriptions;
