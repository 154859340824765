import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { logDebug, logError } from './utils/logger';
import { setToken } from './utils/session';
import { showErrorToast } from './utils/toastUtils';

interface MsalConfig {
    auth: {
        clientId: string;
        authority: string;
        redirectUri: string;
    },
    cache: {
        cacheLocation: string;
        storeAuthStateInCookie: boolean;
    }
}

export interface UserData {
    id: string;
    username: string;
    tenantId?: string;
    name: string;
    oid?: string;
    email?: string;
    token: string;
    xuid?: string;
    gamertag?: string;
    ageGroup?: string;
    serviceType?: string;
    accessToken?: string;
    // playerId?: string;
}

interface IdTokenClaims {
    name: string;
    oid: string;
    [key: string]: any;
}

const msalConfig: MsalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID!, // Replace with your Azure AD app's client ID
    authority: process.env.REACT_APP_MSAL_AUTHORITY!, // Replace with your Azure AD tenant ID
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI!, // Replace with your app's redirect URI
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to true if you're having issues on IE11 or Edge
  },
};

// const pedegreeConfig = {
//     auth: {
//       clientId: process.env.REACT_APP_MSAL_CLIENT_ID!, // Replace with your Azure AD app's client ID
//       authority: `https://login.microsoftonline.com/${process.env.REACT_APP_PEDEGREE_TENANT_ID}`, // Replace with your Azure AD tenant ID
//       redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI!, // Replace with your app's redirect URI
//     },
//     cache: {
//       cacheLocation: 'sessionStorage', // This configures where your cache will be stored
//       storeAuthStateInCookie: false, // Set this to true if you're having issues on IE11 or Edge
//     },
//   };

export const msalInstance = new PublicClientApplication(msalConfig);
// export const pedegreeInstance = new PublicClientApplication(pedegreeConfig);


async function exchangeForXSTSToken(xboxLiveToken: string) {
    const xstsAuthUrl = "https://xsts.auth.xboxlive.com/xsts/authorize";
    const body = {
        "Properties": {
            "SandboxId": "RETAIL",
            "UserTokens": [xboxLiveToken]
        },
        "RelyingParty": "http://xboxlive.com",
        "TokenType": "JWT"
    };

    try {
        const response = await fetch(xstsAuthUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(body)
        });

        if (response.ok) {
            const jsonResponse = await response.json();
            // console.log("XSTS token: ", jsonResponse.Token);
            // console.log("GamerTag: ", jsonResponse.DisplayClaims.xui[0].gtg);
            // console.log("XUID: ", jsonResponse.DisplayClaims.xui[0].xid);
            // console.log("Age group: ", jsonResponse.DisplayClaims.xui[0].agg);
            return jsonResponse;
            // getXUID(jsonResponse.Token, jsonResponse.DisplayClaims.xui[0].uhs);
        } else {
            logError("Failed to exchange for XSTS token", response.statusText);
            const errorResponse = await response.json();
            logError("Error details: ", errorResponse);
        }
    } catch (error) {
        logError("Error exchanging for XSTS token", error);
    }
}

//   const handleCaptcha = (value) => {
//     setFormData((prevData) => ({ ...prevData, recaptchaToken: value }));
//     setCaptchaVerified(!!value);
//   };
async function authorizeXboxLive(accessToken: string) {
    const xboxLiveAuthUrl = "https://user.auth.xboxlive.com/user/authenticate";
    const body = {
        "Properties": {
            "AuthMethod": "RPS",
            "SiteName": "user.auth.xboxlive.com",
            "RpsTicket": `d=${accessToken}` // 'd=' prefix indicates the token is from Azure AD
        },
        "RelyingParty": "http://auth.xboxlive.com",
        "TokenType": "JWT"
    };

    try {
        const response = await fetch(xboxLiveAuthUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(body)
        });

        if (response.ok) {
            const jsonResponse = await response.json();
            // console.log("Xbox Live token: ", jsonResponse.Token);
            const xstsToken = await exchangeForXSTSToken(jsonResponse.Token);
            // await getXUID(jsonResponse.Token, jsonResponse.DisplayClaims.xui[0].uhs);
            return xstsToken;
            // Use the Xbox Live token for further Xbox Live API calls
        } else {
            logError("Failed to authenticate with Xbox Live", response.statusText);
        }
    } catch (error) {
        logError("Error authenticating with Xbox Live", error);
    }
  }

//   export async function getPedegreeToken() {
//     try{
//         const account = pedegreeInstance.getAllAccounts()[0];
//         if (!account) {
//             throw new Error("No account found");
//         }
//         const silentRequest = {
//             scopes: ['User.Read'],
//             account: account
//         };
//         const response = await pedegreeInstance.acquireTokenSilent(silentRequest);
//         return handlePedegreeToken(response);
//     }
//     catch (silentError) {
//         try {
//             const interactiveRequest = {
//                 scopes: ['User.Read'],
//                 prompt: 'select_account'
//             };
//             const response = await pedegreeInstance.loginPopup(interactiveRequest);
//             return handlePedegreeToken(response);
//         } catch (interactiveError) {
//             console.error("Interactive login failed", interactiveError);
//             if (interactiveError.msg) {
//                 toast.error(interactiveError.msg);
//             }
//             throw interactiveError;
//         }
//     }
//   }

export async function getPedegreeMsalToken() {
    try{
        const account = msalInstance.getAllAccounts();
        if (!account) {
            throw new Error("No account found");
        }

        const filteredAccounts = account.filter(a => a.tenantId === process.env.REACT_APP_PEDEGREE_TENANT_ID);
        if (filteredAccounts.length === 0) {
            throw new Error("No account found");
        }

        const silentRequest = {
            scopes: ['User.Read'],
            account: filteredAccounts[0],
            authority: `https://login.microsoftonline.com/${process.env.REACT_APP_PEDEGREE_TENANT_ID}`
        };
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        return handlePedegreeToken(response);
        } catch {
        try {
            const interactiveRequest = {
                scopes: ['User.Read'],
                prompt: 'select_account',
                authority: `https://login.microsoftonline.com/${process.env.REACT_APP_PEDEGREE_TENANT_ID}`
            };
            const response = await msalInstance.loginPopup(interactiveRequest);
            return handlePedegreeToken(response);
        } catch (interactiveError: any) {
            logError("Interactive login failed", interactiveError);
            if (interactiveError.msg) {
                showErrorToast(interactiveError.msg);
            }
            throw interactiveError;
        }
    }

  }

export async function getMSALToken() {
    try {
        // Attempt to acquire token silently
        const account = msalInstance.getAllAccounts()[0];
        if (!account) {
            throw new Error("No account found");
        }
        
        const silentRequest = {
            scopes: ['XboxLive.signin', 'XboxLive.offline_access', 'openid'],
            account: account,
            
        };
        
        const response: AuthenticationResult = await msalInstance.acquireTokenSilent(silentRequest);
        return handleTokenResponse(response);
    } catch {
        // console.log("Silent token acquisition failed, falling back to interactive login", silentError);

        // Fallback to interactive login
        try {
            const interactiveRequest = {
                scopes: ['XboxLive.signin', 'XboxLive.offline_access', 'openid'],
                prompt: 'select_account'
            };

            const response = await msalInstance.loginPopup(interactiveRequest);
            return handleTokenResponse(response);
        } catch (interactiveError: any) {
            logError("Interactive login failed", interactiveError);
            if (interactiveError.msg) {
                showErrorToast(interactiveError.msg);
            }
            throw interactiveError;
        }
    }
}

//TODO: capture the accessTokens and continue to refresh them with an azure function every 
//10 mins or so to migrate into online play.
async function handlePedegreeToken(response: AuthenticationResult) {
    logDebug(response); // Log the response object for debugging

    // Extract user information from the response
    const user: UserData = {
        id: response.account.homeAccountId,
        tenantId: response.account.tenantId,
        // playerId: response.account.homeAccountId,
        username: response.account.username,
        name: (response.idTokenClaims as IdTokenClaims).name,
        oid: (response.idTokenClaims as IdTokenClaims).oid,
        accessToken: response.accessToken, // Store the access token - this is what is needed for authentication.
        token: response.idToken // Store the ID token
    };

    // Store the token in session storage
    setToken(response.idToken);
    return user;
}

//TODO: capture the accessTokens and continue to refresh them with an azure function every 
//10 mins or so to migrate into online play.
async function handleTokenResponse(response: AuthenticationResult) {
    // console.log(response); // Log the response object for debugging

    // Extract user information from the response
    const user: UserData = {
        id: response.account.homeAccountId,
        // playerId: response.account.homeAccountId,
        username: response.account.username,
        name: (response.idTokenClaims as IdTokenClaims).name,
        oid: (response.idTokenClaims as IdTokenClaims).oid,
        // _accessToken: response.accessToken, // Store the access token
        token: response.idToken // Store the ID token
    
    };

    // Store the token in session storage
    setToken(response.idToken);

    // Do Xbox Live Auth
    //TODO: handle case where user pedegree employee?
    const xstsToken = await authorizeXboxLive(response.accessToken);
    // user["_xstsToken"] = xstsToken.Token;
    user["xuid"] = xstsToken.DisplayClaims.xui[0].xid;
    user["gamertag"] = xstsToken.DisplayClaims.xui[0].gtg;
    user["ageGroup"] = xstsToken.DisplayClaims.xui[0].agg;
    // user["_uhs"] = xstsToken.DisplayClaims.xui[0].uhs;

    user["email"] = `${user.username}`;
    // user["playerId"] = `xuid${user["xuid"]}`;
    user["serviceType"] = 'xuid'

    return user;
}


//   export async function getMSALToken() {
//     try {
//       const response = await msalInstance.loginPopup({
//         scopes: ['XboxLive.signin', 'XboxLive.offline_access'],
//         prompt: 'select_account'
//       });
  
//       console.log(response); // Log the response object for debugging
  
//       // Extract user information from the response
//       const user = {
//         id: response.account.homeAccountId,
//         username: response.account.username,
//         name: response.idTokenClaims.name,
//         oid: response.idTokenClaims.oid,
//         _accessToken: response.accessToken, // Store the access token
//         token: response.idToken // Store the ID token
//       };
  
//       // Store the token in session storage
//       setToken(response.idToken);
  
//       // Do Xbox Live Auth
//       const xstsToken = await authorizeXboxLive(response.accessToken);
//       user["_xstsToken"] = xstsToken.Token;
//       user["xuid"] = xstsToken.DisplayClaims.xui[0].xid;
//       user["gamertag"] = xstsToken.DisplayClaims.xui[0].gtg;
//       user["ageGroup"] = xstsToken.DisplayClaims.xui[0].agg;
//       user["_uhs"] = xstsToken.DisplayClaims.xui[0].uhs;
  
//       return user;
  
//     } catch (error) {
//       if (error.msg) {
//         toast.error(error.msg);
//       }
//       console.error(error);
//       throw error; // Propagate the error to be handled by the caller
//     }
//   };

// export async function getMSALToken() {
//     msalInstance.loginPopup({
//     scopes: ['XboxLive.signin', 'XboxLive.offline_access'], // Requesting Group.Read.All scope
//     prompt: 'select_account'
//   }).then(async response => {
//     console.log(response); // Log the response object for debugging
//     // Extract user information from the response
//     const user = {
//       id: response.account.homeAccountId,
//       username: response.account.username,
//       name: response.idTokenClaims.name,
//       oid: response.idTokenClaims.oid,
//       _accessToken: response.accessToken, // Store the access token
//       token: response.idToken // Store the ID token
//     };
//     // Store the token in session storage
//     setToken(response.idToken);

//     //Do xbox Live Auth:
//     const xstsToken = await authorizeXboxLive(response.accessToken);
//     user["_xstsToken"] = xstsToken.Token;
//     user["xuid"] = xstsToken.DisplayClaims.xui[0].xid;
//     user["gamertag"] = xstsToken.DisplayClaims.xui[0].gtg;
//     user["ageGroup"] = xstsToken.DisplayClaims.xui[0].agg;
//     user["_uhs"] = xstsToken.DisplayClaims.xui[0].uhs;

//     // await getUserProfile(xstsToken.Token, xstsToken.DisplayClaims.xui[0].uhs, xstsToken.DisplayClaims.xui[0].xid);
//     return user;
//     // loginUserSSO(user).then(response => {
//     //   onLogin(response);
//     // }).catch(error => {
//     //   if(error.message){
//     //     toast.error(error.message);
//     //   }
    
//     // });

//   }).catch(error => {
//     if(error.msg){
//       toast.error(error.msg);
//     }
//     console.error(error);
//   });
// };