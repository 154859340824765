import { Card, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";

interface PurchaseProductCardProps { 
  product: any,
  mode: string,
}

const PurchaseProductCard: React.FC<PurchaseProductCardProps> = ({ product, mode }: PurchaseProductCardProps) => (
    <Card sx={{ textAlign: 'center', color: 'secondary.main' }}>
      <CardHeader title={mode === 'giftcard' ? "Redeem Product" : "Purchasing Product"} />
      <CardMedia
        component="img"
        alt={product?.name}
        height="100"
        image={product?.images[0]}
        sx={{ objectFit: 'contain', width: 'auto', maxHeight: '100px', margin: '0 auto' }}
      />
      <CardContent>
        <Typography variant="body1" color="text.secondary">
          <em>{product?.qty ?? 1}x</em>: {product?.name}
        </Typography>
        {product?.active_price?.unit_amount && (
          <Typography variant="h6" color="text.secondary" sx={{ justifyContent: 'left' }}>
            <em>Subtotal</em>: ${product?.qty * product?.active_price.unit_amount / 100}
          </Typography>
        )}
      </CardContent>
    </Card>
  );

export default PurchaseProductCard;