import { Box, Card } from "@mui/material";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProductById, validateProductKey } from "../../api/store";
import { logDebug } from "../../utils/logger";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import ProductKeyInputCard from "./ProductKeyInput";



interface RedeemProps {
  onClose?: () => void;
}
  const Redeem: React.FC<RedeemProps> = ({ onClose }: RedeemProps) => {
  const navigate = useNavigate();
  const pageRefresh = useRef(true);
  const { giftCardId: base64_id } = useParams();

  const handleRedeem = async (key: string) => {
    try {

      const data = await validateProductKey(key);
      logDebug('response', JSON.stringify(data));
      if (data.success) {
        showSuccessToast('Validated Gift Card!');
        logDebug('Getting Product details')
        const { data: product } = await getProductById(data.data.stripeProductId);
        logDebug('Product details', product);
        navigate(`/beta/register`, { state: { giftCardId: key, giftCard: data.data, product: product } });
        if(onClose) {
          setTimeout(onClose, 300);
        }
        //TODO: go to the right URL here.
      } else {
        showErrorToast('Invalid gift card code.');
      }
    } catch (error: any) {
      showErrorToast(error.message || 'Failed to redeem gift card.');
    }
  };

  useEffect(() => {

    const autoRedeem = async () => {
      if(!base64_id) {
        return;
      }
      const encodedGiftCardId = atob(base64_id!);
      logDebug('Redeeming Gift Card ', encodedGiftCardId);
      await handleRedeem(encodedGiftCardId);
    }

    if(pageRefresh.current) {
      pageRefresh.current = false;
      autoRedeem();
    }
  });

  

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card sx={{ maxWidth: 450 }}>
          <ProductKeyInputCard product={undefined} onSubmit={handleRedeem} />
        </Card>
      </Box>
    </>
  );
};

export default Redeem;