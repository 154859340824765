import { Box, Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const Success: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const handleProfileRedirect = () => {
        navigate('/protected/profile');
    };

    if(!user){
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h2>Thank you for your payment</h2>
        </div>
        )
    }

    return (
        <Box sx={{ mt: 4, px: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={8}>
                    <Card style={{ padding: '20px',  textAlign: 'center' }}>
                    <h2>Success! Your purchase is confirmed.</h2>
                    <Typography variant="body1" color="text.primary"> 
                        Please check your email ({user.email}) for your receipt and detailed instructions to complete your signup. If you don’t see the email in your inbox, be sure to check your spam or junk folders. The email will come from 
                        <span style={{ color: 'blue' }}> no-reply@pedegreestudios.com</span>.
                    </Typography>
                    <Button sx={{mt: 2}} variant="contained" color="primary" onClick={handleProfileRedirect}>
                        Go to Profile
                    </Button>
                </Card>
                </Grid>
            </Grid>
            </Box>
    );
};

export default Success;