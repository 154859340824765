import { Box, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { getUserProfile } from '../../services/api';
import { logDebug } from '../../utils/logger';
import { showErrorToast } from '../../utils/toastUtils';
import FeedbackCard from './FeedbackCard';
import ProfileCard from './ProfileCard';
import Subscriptions from './Subscriptions';

const Profile: React.FC = () => {
  const [dlcData, setDlcData] = useState<any[]>([]);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const navigate = useNavigate();
  const pageRefresh = useRef(true);
  const { user, logout } = useAuth();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if(!user) {
        logDebug('User not found. Redirecting to login');
        navigate('/login');
        return;
      }
      try {
        setLoadingUserData(true);
        const { user, dlcList } = await getUserProfile();
        // setFormData(user);
        setDlcData(dlcList || []);
        logDebug('Fetched user profile successfully', { user, dlcList });
      } catch (error) {
        logDebug('Failed to fetch user profile', error);
        showErrorToast('Failed to fetch user profile');
        logout();
        navigate('/login');
      } finally {
        setLoadingUserData(false);
      }
    };

    if (pageRefresh.current) {
      fetchUserProfile();
      pageRefresh.current = false;
    }
  }, [navigate, logout, user]);

  return (
    <Box sx={{ mt: 0, px: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ProfileCard name={user!.name} email={user!.email!} gamertag={user!.gamertag!} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Subscriptions subscriptions={dlcData} loading={loadingUserData} />
        </Grid>
        <Grid item xs={12}>
          <FeedbackCard email={user!.email!} name={user!.name} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
