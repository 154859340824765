import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Box, Button, Card, CardContent, CircularProgress, Grid, Tab, tabClasses, Tabs, tabsClasses, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ProductsProvider, useProducts } from '../../contexts/ProductContext';
// import RedeemDialog from '../RedeemProduct/RedeemDialog';
import { logDebug } from '../../utils/logger';
import { tabItemStyles, tabsStyles, toSx } from './CustomTabs';
import ProductCard from './ProductCard';

const Storefront: React.FC = () => {
  try {
    // Attempt to use the Products context
    useProducts();
    return <StorefrontContent />;
  } catch {
    // Fallback to provide ProductsProvider if missing
    return (
      <ProductsProvider>
        <StorefrontContent />
      </ProductsProvider>
    );
  }
};


const tabItemSx = toSx(tabItemStyles, tabClasses);

const StorefrontContent: React.FC = () => {
  const { products, loading, setProducts } = useProducts();
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    setProducts((currentProducts) =>
      currentProducts.map((product) => {
        const updatedProduct = { ...product };
        if (updatedProduct.additional_prices && updatedProduct.additional_prices[selectedTab]) {
          updatedProduct.active_price = { ...updatedProduct.additional_prices[selectedTab] };
        } else {
          updatedProduct.active_price = undefined;
        }
        return updatedProduct;
      })
    );
    logDebug('Products updated for tab:', selectedTab);
  }, [selectedTab, setProducts]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Card sx={{ textAlign: 'left', color: 'secondary.main' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={12} md={8}>
              <Typography variant="h4" color="text.secondary">
                Overqualified! Early Access Store
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Overqualified! is a digital learning platform that teaches learners
                about semiconductor manufacturing, battery processes, chemistry, and
                biology. Designed for ages 10+, this learning pass gives you access to
                our modded Minecraft servers to build real-world knowledge through
                in-game experiences. 

                <br />
                <br />
                Currently in Early Access, our platform provides learning experiences for 
                Semiconductors through Overclocked<sup>TM</sup>!. We will be releasing more game 
                content and expand to Overreacted<sup>TM</sup>! for chemistry, 
                Overcharged<sup>TM</sup>! for battery processes, and Overgrown<sup>TM</sup>! for biology
                over the coming months.
                
                Learn more about Overqualified! and read the latest
                articles on our <Link to="https://www.pedegreestudios.com/news">blog</Link>.
              </Typography>
                <br />
              <Typography variant="caption" color="text.secondary">
                <strong>Platform Support:</strong> Currently, we only support Windows, Linux, iOS, and Android devices. MacOS and consoles (Xbox/Playstation/Nintendo Switch) are <em>not supported.</em> 
              </Typography>
              <br />
              <br />
              <Typography variant="body2" color="text.secondary">
                If you already have a product key, please click on the Redeem Code button to activate your license.
              </Typography>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button fullWidth variant="contained" color="primary">
                Redeem Code
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box sx={{ mt: 4, justifyItems: 'center' }}>
      <Tabs 
        value={selectedTab} 
        onChange={handleTabChange} 
        sx={[toSx(tabsStyles, tabsClasses)]}
        // indicatorColor="primary" 
        // textColor="primary"
        >
          <Tab label="Single Pass" value={0} sx={tabItemSx!} icon={<PersonIcon />} />
          <Tab label="2-4 Passes" value={1} sx={tabItemSx!} icon={<SupervisorAccountIcon />}/>
          <Tab label="5+ Passes" value={2} sx={tabItemSx!} icon={<GroupsIcon />}/>
        </Tabs>
      </Box>
      <Grid container spacing={4} mt={1} justifyContent="center">
        {products.map((product) => (
          <Grid item key={product.id} xs={12} sm={6} md={3}>
            <ProductCard product={product} selectedTabId={selectedTab} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Storefront;