import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <main style={{ marginTop: '64px', padding: '16px' }}>
        {children}
      </main>
      <ToastContainer />
    </>
  );
};

export default MainLayout;
