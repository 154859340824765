import { Dialog } from '@mui/material';
import React from 'react';
import Redeem from './Redeem';

interface RedeemDialogProps {
  open: boolean;
  product: any;
  onClose: () => void;
}

const RedeemDialog: React.FC<RedeemDialogProps> = ({ open, onClose }) => {
  
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <Redeem onClose={onClose} />
      </Dialog>
    </>
  );
};

export default RedeemDialog;
