const API_URL = process.env.REACT_APP_CUSTOM_API_URL as string;

/**
 * Get the user's profile.
 * @returns {Promise<any>} - The response JSON object containing user profile data.
 * @throws {Error} - If the request fails.
 */
export const getUserProfile = async (): Promise<any> => {
    const response = await fetch(`${API_URL}/profile`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      const responseText = await response.json();
      throw new Error('Failed to fetch user profile: ' + (responseText.error ?? response.status));
    }
  
    return await response.json();
  };