import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useState } from "react";

interface ConfirmGiftCardRegisterDialogProps {
  open: boolean,
  user: any,
  product: any,
  giftCard: any,
  onConfirm: () => void,
  onClose: () => void
}


const ConfirmGiftCardRegisterDialog: React.FC<ConfirmGiftCardRegisterDialogProps> = (
    {
      open,
      user,
      product,
      giftCard,
      onConfirm,
      onClose
    }: ConfirmGiftCardRegisterDialogProps
  ) => {
  
      const [userConsent, setUserConsent] = useState(false);
  
      return (
        <Dialog 
          open={open} 
          onClose={onClose}
          scroll="paper"
          PaperProps={{
            style: { maxHeight: '80vh' }
          }}
          >
            <DialogTitle>Confirm Product Activation</DialogTitle>
            <DialogContent>
          {/* <Card>
            <CardHeader title="Confirm Product Activation" />
            <CardContent> */}
              <RegistrationTable product={product} user={user} giftCard={giftCard} />
              {/* <Typography variant="body1" color="text.secondary" gutterBottom>
                You are about to register
                <Typography component={"span"} fontWeight={"bold"}> {product?.name} </Typography>
                with your account using the email address: <Typography component={"span"} fontWeight={"bold"}> {user?.email} </Typography> and
                Microsoft gamertag: <Typography component={"span"} fontWeight={"bold"}>{user?.gamertag}</Typography>.
                Your Microsoft account will need a valid Minecraft License to access the server.
                <br />
              </Typography> */}
              <br />
              <Typography variant="body1" color="text.secondary" gutterBottom>
                Once activated, this product key
                cannot be transferred to another account. By activating this product key, you also acknowledge that you have read the 
                <Typography component={"span"} fontWeight={"bold"}>
                  <a href="https://www.pedegreestudios.com/tos" target="_blank" rel="noopener noreferrer"> Terms of Service </a>
                </Typography>
                and <Typography component={"span"} fontWeight={"bold"}>
                  <a href="https://www.pedegreestudios.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </Typography>.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Please confirm your consent to proceed.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <Checkbox
                    checked={userConsent}
                    required={true}
                    onChange={(e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => setUserConsent(e.target.checked)}
                    color="primary"
                  />
                  <Typography variant="body2" color="text.secondary">
                    I acknowledge and consent to the terms and conditions.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: '80%', mt: 2, mr: 1 }}
                  disabled={!userConsent}
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
                <Button variant="contained" color="secondary" sx={{ width: '80%', mt: 2 }} onClick={onClose}>
                  Cancel
                </Button>
              </Box>
            </DialogContent>
        </Dialog>
      );
    };
  
  interface RegistrationTableProps { product: any; user: any; giftCard: any }

  const RegistrationTable: React.FC<RegistrationTableProps> = ({ product, user, giftCard }: RegistrationTableProps) => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body1" color="text.secondary">
                  Product Name:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{product?.name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1" color="text.secondary">
                  Expiration Date:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{giftCard?.expirationDate}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1" color="text.secondary">
                  Email address:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{user?.email}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1" color="text.secondary">
                  Xbox Live gamertag:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{user?.gamertag}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="body1" color="text.secondary">
                  <em>Note: </em>Your Microsoft account will need a valid Minecraft License to access the server.
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

export default ConfirmGiftCardRegisterDialog;