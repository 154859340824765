import { Microsoft } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material";

interface ExistingUserLoginProps {
    loading: boolean;
    handleMSALLogin: () => void;
}

const ExistingUserLogin: React.FC<ExistingUserLoginProps> = ({ loading, handleMSALLogin }: ExistingUserLoginProps) => (
    <Card sx={{ width: '100%', flexGrow: 1}}>
      <CardHeader title="Existing Users: Login" />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Login with your account to continue to your profile page.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button
            variant="contained"
            startIcon={!loading && <Microsoft />}
            color="primary"
            sx={{ mt: 2, width: '80%' }}
            disabled={loading}
            onClick={handleMSALLogin}
          >
            {loading && <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />}
            Login
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

export default ExistingUserLogin;