// src/components/AdminPortal/ContainerInstances.tsx
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StopIcon from '@mui/icons-material/Stop';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { restartContainerGroup, startContainerGroup, stopContainerGroup } from '../../services/api';
import { showErrorToast } from '../../utils/toastUtils';

type ContainerGroup = {
  name: string;
  state: string;
  resourceGroup: string;
  containers: Array<{ name: string; status: string }>;
};

type ContainerInstancesProps = {
  containerGroups: ContainerGroup[];
  fetchContainerData: () => void;
};

const ContainerInstances: React.FC<ContainerInstancesProps> = ({ containerGroups, fetchContainerData }) => {
  const [expandedGroup, setExpandedGroup] = useState<string | null>(null);
  const [loadingGroup, setLoadingGroup] = useState<string | null>(null);

  const handleExpandClick = (groupName: string) => {
    setExpandedGroup(expandedGroup === groupName ? null : groupName);
  };

  const handleAction = async (action: 'start' | 'stop' | 'restart', resourceGroup: string, groupName: string) => {
    const token = sessionStorage.getItem('token')!;
    setLoadingGroup(groupName);
    try {
      if (action === 'start') await startContainerGroup(token, resourceGroup, groupName);
      if (action === 'stop') await stopContainerGroup(token, resourceGroup, groupName);
      if (action === 'restart') await restartContainerGroup(token, resourceGroup, groupName);

      toast.success(`Container group ${action}ed successfully!`);
      fetchContainerData(); // Refresh data after action
    } catch {
      showErrorToast(`Failed to ${action} the container group.`);
    } finally {
      setLoadingGroup(null);
    }
  };

  const getStateColor = (state: string) => {
    switch (state) {
      case 'Running':
        return 'success';
      case 'Stopped':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Card>
      <CardHeader title="Container Instances" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {containerGroups.map((group) => (
              <React.Fragment key={group.name}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => handleExpandClick(group.name)} disabled={loadingGroup === group.name}>
                      {expandedGroup === group.name ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {group.name}
                  </TableCell>
                  <TableCell>
                    <Chip label={group.state} color={getStateColor(group.state)} />
                  </TableCell>
                  <TableCell>
                    {loadingGroup === group.name ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <IconButton
                          onClick={() => handleAction('start', group.resourceGroup, group.name)}
                          disabled={group.state !== 'Stopped'}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleAction('stop', group.resourceGroup, group.name)}
                          disabled={group.state !== 'Running'}
                        >
                          <StopIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleAction('restart', group.resourceGroup, group.name)}
                          disabled={group.state !== 'Running'}
                        >
                          <RestartAltIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Collapse in={expandedGroup === group.name}>
                      <Box sx={{ padding: 2 }}>
                        <Typography variant="h6">Containers:</Typography>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Container Name</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {group.containers.map((container) => (
                              <TableRow key={container.name}>
                                <TableCell>{container.name}</TableCell>
                                <TableCell>{container.status}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default ContainerInstances;
