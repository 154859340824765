import { tabClasses, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

export const tabsStyles = () => ({
    root: {
      backgroundColor: "#eee",
      borderRadius: "10px",
      minHeight: 44,
    },
    flexContainer: {
      position: "relative",
      padding: "0 3px",
      zIndex: 1,
    },
    indicator: {
      top: 3,
      bottom: 3,
      right: 3,
      height: "auto",
      borderRadius: "8px",
      backgroundColor: "#fff",
      boxShadow: "0 4px 12px 0 rgba(0,0,0,0.16)",
    },
  });
  
  export const tabItemStyles = (theme: Theme) => ({
    root: {
      fontWeight: 500,
      minHeight: 44,
      minWidth: 96,
      opacity: 0.7,
      color: theme.palette.text.primary,
      textTransform: "initial",
      "&:hover": {
        opacity: 1,
      },
      [`&.${tabClasses.selected}`]: {
        color: theme.palette.text.primary,
        opacity: 1,
      },
      [theme.breakpoints.up("md")]: {
        minWidth: 120,
      },
    },
  });
  
  export function toSx<ClassKey extends string>(
    styles: (theme: Theme) => Partial<Record<ClassKey, any>>,
    classes: Record<ClassKey, string>
  ) {
    return function sxCallback(theme: Theme): SystemStyleObject<Theme> {
      let sx: SystemStyleObject<Theme> = {};
      Object.entries<any>(styles(theme)).forEach(([key, value]) => {
        if (key === "root") {
          sx = { ...sx, ...value };
        } else {
          sx = { ...sx, [`& .${classes[key as ClassKey]}`]: value };
        }
      });
      return sx;
    } as SystemStyleObject<Theme>;
  }