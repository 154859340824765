import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getProductList } from '../services/api';
import { Product, ProductPrice } from '../types/api';
import { logDebug } from '../utils/logger';
import { showErrorToast } from '../utils/toastUtils';

interface ProductsContextValue {
  products: Product[];
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  loading: boolean;
//   selectedTab: number;
//   setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  fetchProducts: () => Promise<void>;
}

const ProductsContext = createContext<ProductsContextValue | undefined>(undefined);

export const ProductsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
//   const [selectedTab, setSelectedTab] = useState<number>(0);
  // const { validateBetaAccess } = useAuth();

  // const navigate = useNavigate();

  const fetchProducts = useCallback(async () => {
    try {
      // const session = await validateAccessSession();
      // logDebug('Session validated successfully:', session);
      

      const { data: response } = await getProductList();
      const sortedProducts: Product[] = response.sort((a: Product, b: Product) => {
        if (a.default_price?.unit_amount == null) return 1;
        if (b.default_price?.unit_amount == null) return -1;
        return a.default_price.unit_amount - b.default_price.unit_amount;
      });

      for (const sortedProd of sortedProducts) {
        sortedProd.active_price = sortedProd.default_price;
        sortedProd.qty = 1;
        if (sortedProd.additional_prices) {
          sortedProd.additional_prices.sort((a: ProductPrice, b: ProductPrice) => {
            const aAmount = a.unit_amount ?? parseFloat(a.unit_amount_decimal);
            const bAmount = b.unit_amount ?? parseFloat(b.unit_amount_decimal);
            return bAmount - aAmount;
          });
          sortedProd.active_price = sortedProd.additional_prices[0];
        }
      }

      setProducts(sortedProducts);
      logDebug('Fetched products:', sortedProducts);
    } catch (error: any) {
      showErrorToast(error.message || 'Failed to validate session or fetch products.');
      // validateBetaAccess(false);
      // navigate('/beta-access');
    } finally {
      setLoading(false);
    }
  }, []);

//   useEffect(() => {
//     // Update product prices based on the selected tab
//     setProducts((currentProducts) =>
//       currentProducts.map((product) => {
//         const updatedProduct = { ...product };
//         if (updatedProduct.additional_prices && updatedProduct.additional_prices[selectedTab]) {
//           updatedProduct.active_price = { ...updatedProduct.additional_prices[selectedTab] };
//         } else {
//           updatedProduct.active_price = product.default_price;
//         }
//         return updatedProduct;
//       })
//     );
//     logDebug('Products updated for tab:', selectedTab);
//   }, [selectedTab]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <ProductsContext.Provider value={
        { products, loading, fetchProducts, setProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = (): ProductsContextValue => {
  const context = useContext(ProductsContext);
  if (!context) {
    throw new Error('useProducts must be used within a ProductsProvider');
  }
  return context;
};
