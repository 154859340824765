import { Box, CircularProgress, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import ConfirmDialog from './ConfirmDialog';
import { useAuth } from '../../contexts/AuthContext';
import {
  getAdminPortalData,
  getContainerData,
  tryAdminLogin
} from '../../services/api';
import { UserTableData } from '../../types/api';
import { logDebug, logError } from '../../utils/logger';
import { showErrorToast } from '../../utils/toastUtils';
import ContainerInstances from './ContainerInstances';
import UserTable from './UserTable';

type ContainerGroup = {
  name: string;
  state: string;
  resourceGroup: string;
  containers: Array<{ name: string; status: string }>;
};

const AdminPortal: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserTableData[]>([]);
  const [containerGroups, setContainerGroups] = useState<ContainerGroup[]>([]);
  // const [logModalOpen, setLogModalOpen] = useState(false);
  // const [currentLogs] = useState('');
  const [validatingAdminAccess, setValidatingAdminAccess] = useState(true);
  const { accessToken, idToken } = useAuth();
  // const userToken = accessToken ?? "";

  useEffect(() => {
    const validateToken = async () => {
      const token = accessToken;
      // const token = sessionStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
      try {
        const response = await tryAdminLogin(token);
        if (response.message === 'User is an admin') {
          setValidatingAdminAccess(false);
        } else {
          showErrorToast('User is not an admin');
          navigate('/login');
        }
      } catch {
        showErrorToast('Invalid credentials');
        navigate('/login');
      }
    };
    validateToken();
  }, [navigate]);

  const fetchContainerData = useCallback(async () => {
    try {
      
      // const userToken = sessionStorage.getItem('token')!;
      // logDebug(`User token is ${userToken}`);
      if(!idToken) {
        logError('Token not found');
        return;
      }
      const data = await getContainerData(idToken);
      logDebug('Container data', data);
      setContainerGroups(data);
    } catch {
      showErrorToast('Failed to fetch container data');
    }
  }, []);

  const fetchUsers = useCallback(async () => {
    try {
      if(!idToken) {
        logError('Token not found');
        return;
      }
      const { data: newUsers } = await getAdminPortalData(idToken, 5, undefined);
      logDebug('Users data', newUsers);
      setUsers(newUsers.users);
    } catch (err) {
      logError('Failed to fetch users', err);
      showErrorToast('Failed to fetch users');
    }
  }, [idToken]);

  useEffect(() => {
    fetchUsers();
    fetchContainerData();
  }, []);

  if (validatingAdminAccess) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <UserTable users={users} />
      </Grid>
      <Grid item xs={12}>
        <ContainerInstances containerGroups={containerGroups} fetchContainerData={fetchContainerData} />
      </Grid>
      {/* <LogsModal open={logModalOpen} onClose={() => setLogModalOpen(false)} logs={currentLogs} /> */}
      {/* <ConfirmDialog /> */}
    </Grid>
  );
};

export default AdminPortal;
